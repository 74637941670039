body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.textSize{
 font-size:12px;
 text-align: center;
 font-weight: bold;
 text-transform:uppercase
 /* margin: 2rem 0rem 2rem 0rems */
}

.centerHeading{
  text-align: center;
  border: 1px solid rgb(141, 137, 137);
  border-radius: 10px;
  background-color: rgb(235, 129, 212);
}
.card-container{
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* background-color: #F2F2F2 */

}
/* .card-text:hover{
  background-color: rgb(241, 193, 103)  ;
  border-radius: 5px;
  transition: 0.3s;
} */
.card-container>div{
  margin: 10px;
  /* border: 1px solid; */
  padding: 5px;
  box-shadow: 3px 3px 3px 3px  #777676;
  border-radius: 10px;
  width: 200px;
}
.card-container>div:hover{
  background-color:rgb(161, 157, 157);
  transition: 0.5s;
}

.MuiCardMedia-img	{
  /* width: 50px; */
  height: 190px;
  /* object-fit: scale-down; */
 /* margin-top: 2rem; */
 padding: 15px;
}
/* .MuiCardMedia-img:hover{
  background-color: rgb(170, 206, 204);
  transition: 0.3s;
} */
/* .drawer{
  background-color: rgba(0, 0, 0, 0.6);
} */


.chart1BMAIN{
  display: flex;
  justify-content: space-around;
  
  
}

.Card{

  width: 200px;
  height:100px ;
  border: 1px grey solid;
  /* padding: 3px; */
  box-shadow: 5px 5px 5px 5px  #777676;
  border-radius: 10px;
  

 

}
/* /CHARTS STYLING/ */
.custom-tooltip {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px solid #c3c3c3;
  border-radius: 10px;}
  .custom-tooltip>span {
    font-weight: bold;
  }

  .Charts{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
   .TimeSelect{
  display: block;
  margin: 50px;
  padding: 50px;

}
.TimeSelect> div{
margin-top: 10px;
}
  /* Chart1b Guage */
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: .5rem;
  }


  
  .head{
    margin-left: 65px;
    
  }
  .container>div{
    /* height: 200px;
    width: 280px; */
   padding: 5px;
  }
  .Cards-1b{
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
      
  }
  .Cards-1b>div{
    /* margin: 2px; */
    padding: 2px;
    /* border: 3px solid #2E3B55;   */
    
    
  }
  .guageGraph{
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    align-items: center; */
    /* flex-wrap: wrap; */
  }
  .LossChart{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    
  }
.MainContainer{
  /* display: flex; */
  /* flex-direction: row; */
}

/* DEFECTS HANDLING/ */
.SelectPlant{
  display: flex;
 flex-direction: row;
 justify-content: flex-start;
 flex-wrap: wrap;
 /* align-items: center; */
}
.SelectPlant>div{
  margin-left: 5px;
  padding-left: 15px;
  /* justify-content: space-around; */
}
.dhForm{
display: flex;
justify-content: space-between;
/* background: linear-gradient(to right, #606c88, #3f4c6b); */
/* align-items: center; */

/* flex-wrap: wrap;

align-items: center;
justify-content: space-between; */
}
.dhForm>div{
  margin: 3rem;
  /* margin-right: 5rem; */
  /* margin-top: 5px;
  padding: 3px; */
  /* column-count: 2;
  column-gap: 5rem;
  width: 50%; */

}
.MuiDialogContent-root{
  /* overflow-y: hidden !important; */
}
.dhForm .div1t6{
  margin-top: 1px;
}

.dhForm .div7t12{
  margin-top: 1px;
}
.div1t6>div{
 margin-top: 5px;
}
.div7t12>div{
  margin-top: 5 px;
 }
.DHContainer{
display: flex;
flex-direction: column;
flex-wrap: wrap;
}
.DHContainer>div{
  /* margin-top: 10px; */
}
.OHContainer{
  /* display: flex; */
  display: flex;
  flex-direction: column;
  height: 100vh;

}
.OHContainer>div{
  margin-top: 20px;
}




/* DHGRAPH */
.DHGCards{
display: flex;
justify-content: space-between;
/* align-items: center; */
flex-wrap: wrap;
}
.DHGCards>div{
  width: 10rem;
  padding: 5px;
}
.graphContainer{  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.graphContainer>div{
  margin: 1px;
}
.graphContainer>div:hover{
  background-color: thistle;
  transition: 2s;
}
/* app bar text logo */
.logoText{
  text-decoration: none;
  transition: 0.5s ease-in-out;
   color: white
}
.logoText:hover{
  text-decoration: "none";
  color: black;
  transition: 0.7s;
}

.logoText1{
  text-decoration: none;
   color: white;
   transition: 0.4s ease-in-out;
   /* border: 1px solid black; */
   border-radius: 5px;
   padding: 3px;
}
.logoText1:hover{
  text-decoration: "none";
  border: 1px solid orange;
  padding: 6px;
  color: orangered;
  transition: 0.4s ;
  border-radius: 10px;
}


@media only screen and (max-width: 700px) {
  .dhForm{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    
  }
  .dhform .div1to6.div7t12>div{
    width: 100%;}
 }
.userCreationForm>div{
  margin-top: 5px;
  padding: 5px;
  
}






